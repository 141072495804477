import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/spindle.ameba.design/spindle.ameba.design/src/components/Layout.tsx";
import SEO from '../../../components/SEO';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageTitle = makeShortcode("PageTitle");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="イントロダクション - イラストレーション" mdxType="SEO" />
    <PageTitle title="イントロダクション" enTitle="Introduction" mdxType="PageTitle" />
    <h2 {...{
      "id": "イラストの役割",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%82%A4%E3%83%A9%E3%82%B9%E3%83%88%E3%81%AE%E5%BD%B9%E5%89%B2",
        "aria-label": "イラストの役割 permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`イラストの役割`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/8c7f3ccadfcae144d47ffa7208f715d1/21b4d/tHKOZ-2345%3A37289.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "54.37499999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/8c7f3ccadfcae144d47ffa7208f715d1/2aaaf/tHKOZ-2345%3A37289.webp 160w", "/static/8c7f3ccadfcae144d47ffa7208f715d1/85e47/tHKOZ-2345%3A37289.webp 320w", "/static/8c7f3ccadfcae144d47ffa7208f715d1/75198/tHKOZ-2345%3A37289.webp 640w", "/static/8c7f3ccadfcae144d47ffa7208f715d1/691bc/tHKOZ-2345%3A37289.webp 960w", "/static/8c7f3ccadfcae144d47ffa7208f715d1/223e5/tHKOZ-2345%3A37289.webp 1280w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/8c7f3ccadfcae144d47ffa7208f715d1/69538/tHKOZ-2345%3A37289.png 160w", "/static/8c7f3ccadfcae144d47ffa7208f715d1/72799/tHKOZ-2345%3A37289.png 320w", "/static/8c7f3ccadfcae144d47ffa7208f715d1/6af66/tHKOZ-2345%3A37289.png 640w", "/static/8c7f3ccadfcae144d47ffa7208f715d1/d9199/tHKOZ-2345%3A37289.png 960w", "/static/8c7f3ccadfcae144d47ffa7208f715d1/21b4d/tHKOZ-2345%3A37289.png 1280w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/8c7f3ccadfcae144d47ffa7208f715d1/6af66/tHKOZ-2345%3A37289.png",
              "alt": "雲、木、ベンチ、携帯を見ている人々など",
              "title": "雲、木、ベンチ、携帯を見ている人々など",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul">{`Amebaのブランドを表現できます`}</li>
      <li parentName="ul">{`情緒的な印象を与えることができます`}</li>
      <li parentName="ul">{`多様性、親しみやすさを体現できます`}</li>
      <li parentName="ul">{`複雑な情報をわかりやすく届けられます`}</li>
    </ul>
    <h2 {...{
      "id": "イラストの種類",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%82%A4%E3%83%A9%E3%82%B9%E3%83%88%E3%81%AE%E7%A8%AE%E9%A1%9E",
        "aria-label": "イラストの種類 permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`イラストの種類`}</h2>
    <p>{`イラストは大きく分けて「キャラ」「オブジェクト」「背景」の3種類があり、それぞれ必要に応じて使い分けることができます。`}</p>
    <h3 {...{
      "id": "キャラ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%82%AD%E3%83%A3%E3%83%A9",
        "aria-label": "キャラ permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`キャラ`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/6cba68f76b50f9adafa50bef16196078/21b4d/tHKOZ-2345%3A37139.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "41.875%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA8klEQVQoz2P4jwX8+/cPjEHg+6+f/+evXPR/4+6N/6/cPP//7dt3cDXYAAM2w0Dg79+/YPrTt8//y+dE/0+aqvZ//9k1ELl/f4gzEKYIWfHFm4f/T9jj/b/tsOr/fVfmQOX/Eu/Cv/8gLrv1/N7/i9cu/Xd2cv5vZGLwv3pi5P/dl2f8//vnH/FeBin6/ec3mL3y2LL/1iHW/7NSs/6bGJn+ryqvhwcFLsOwGvgP6sLXb578X7xn8f/KZY3/w2PC/2/dvgUs/ufPH9IiBRZGMHDm1qn/E9d0/N937hBI4j8YkubCf/CwhMX0H2gw4DMIZiAAmg5ZF+M63tMAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/6cba68f76b50f9adafa50bef16196078/2aaaf/tHKOZ-2345%3A37139.webp 160w", "/static/6cba68f76b50f9adafa50bef16196078/85e47/tHKOZ-2345%3A37139.webp 320w", "/static/6cba68f76b50f9adafa50bef16196078/75198/tHKOZ-2345%3A37139.webp 640w", "/static/6cba68f76b50f9adafa50bef16196078/691bc/tHKOZ-2345%3A37139.webp 960w", "/static/6cba68f76b50f9adafa50bef16196078/223e5/tHKOZ-2345%3A37139.webp 1280w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/6cba68f76b50f9adafa50bef16196078/69538/tHKOZ-2345%3A37139.png 160w", "/static/6cba68f76b50f9adafa50bef16196078/72799/tHKOZ-2345%3A37139.png 320w", "/static/6cba68f76b50f9adafa50bef16196078/6af66/tHKOZ-2345%3A37139.png 640w", "/static/6cba68f76b50f9adafa50bef16196078/d9199/tHKOZ-2345%3A37139.png 960w", "/static/6cba68f76b50f9adafa50bef16196078/21b4d/tHKOZ-2345%3A37139.png 1280w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/6cba68f76b50f9adafa50bef16196078/6af66/tHKOZ-2345%3A37139.png",
              "alt": "スケートボードをしている人。キャップを被りサングラスをしている。",
              "title": "スケートボードをしている人。キャップを被りサングラスをしている。",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "オブジェクト",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%82%AA%E3%83%96%E3%82%B8%E3%82%A7%E3%82%AF%E3%83%88",
        "aria-label": "オブジェクト permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`オブジェクト`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/2e51caccd7f6e85d4a6f7c4b40448d66/21b4d/tHKOZ-2345%3A37202.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "43.125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA8klEQVQoz2P4jwb+/fsHpv/8/fP/8/fPYD5MDFkeF2DAZeDrj2/+bzix5f+nr5/A/C9fv/z/9esnQUNxGggy6N6zB/+/fPvy/8vnL//fvHn9/+/fv3A1uAxlQDcM7sJPb/6vPLT2/+dvn/9//fr1/68/v///+w+Rh9EkGfjj108wRtdIchiCIgMEutf3/j975xyYPX/X4v+FS6v+T9ky8/+v77/wGoxi4F+oou8/vv83rLf937ypC8yPmZb6X6BG8b93X9j/n59/EG8gTBEo3FadXv//8M1jYP6JC6f+9y2Y+H/PsX2kRQo+m//8/kNUOAIA8sK2ICVA/r8AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/2e51caccd7f6e85d4a6f7c4b40448d66/2aaaf/tHKOZ-2345%3A37202.webp 160w", "/static/2e51caccd7f6e85d4a6f7c4b40448d66/85e47/tHKOZ-2345%3A37202.webp 320w", "/static/2e51caccd7f6e85d4a6f7c4b40448d66/75198/tHKOZ-2345%3A37202.webp 640w", "/static/2e51caccd7f6e85d4a6f7c4b40448d66/691bc/tHKOZ-2345%3A37202.webp 960w", "/static/2e51caccd7f6e85d4a6f7c4b40448d66/223e5/tHKOZ-2345%3A37202.webp 1280w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/2e51caccd7f6e85d4a6f7c4b40448d66/69538/tHKOZ-2345%3A37202.png 160w", "/static/2e51caccd7f6e85d4a6f7c4b40448d66/72799/tHKOZ-2345%3A37202.png 320w", "/static/2e51caccd7f6e85d4a6f7c4b40448d66/6af66/tHKOZ-2345%3A37202.png 640w", "/static/2e51caccd7f6e85d4a6f7c4b40448d66/d9199/tHKOZ-2345%3A37202.png 960w", "/static/2e51caccd7f6e85d4a6f7c4b40448d66/21b4d/tHKOZ-2345%3A37202.png 1280w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/2e51caccd7f6e85d4a6f7c4b40448d66/6af66/tHKOZ-2345%3A37202.png",
              "alt": "スマートフォン、ペン、ドレス、モニター",
              "title": "スマートフォン、ペン、ドレス、モニター",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "背景",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E8%83%8C%E6%99%AF",
        "aria-label": "背景 permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`背景`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/7a391395b7569cf2e9b340fdb4dc7496/21b4d/tHKOZ-2345%3A37250.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "35%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAAsTAAALEwEAmpwYAAABKElEQVQoz4WR3U7CQBCFef/XEE0heqGJJF74AyaS4B0mgC1YKCW0i9R2t9saavuZthI1Gp3k5GTO7JzMzDYAiqKoUMaLEjj+mKWYsBSPuMKsuMzTV43WCZGMUEqR5zn7/n00Pg3r4tC+4XZyQN9qc2ca9M0WfatFb3SIvTRZrz22wRbf9wnD8HfDr6JWKbu0oHiDPIN8V9dLTSuNlhodxUTbEBnKvyasRV/4+MIjSTSxjoljRZJqNkLgrB3O7jucDs456rYZzh6qnvxju2+Ge+4MLmheG7R6JzQvDZpXNYzuMSN7jOVOsVZTTHeK663+n3ATPKMSRRAFWE8WtjNnNp+xcBfV7cq1hScqTnTy/w2zXVY9rKHRcY0y13Fc/a5UEiklWZb9MHwHaM8Sxf3LYa8AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/7a391395b7569cf2e9b340fdb4dc7496/2aaaf/tHKOZ-2345%3A37250.webp 160w", "/static/7a391395b7569cf2e9b340fdb4dc7496/85e47/tHKOZ-2345%3A37250.webp 320w", "/static/7a391395b7569cf2e9b340fdb4dc7496/75198/tHKOZ-2345%3A37250.webp 640w", "/static/7a391395b7569cf2e9b340fdb4dc7496/691bc/tHKOZ-2345%3A37250.webp 960w", "/static/7a391395b7569cf2e9b340fdb4dc7496/223e5/tHKOZ-2345%3A37250.webp 1280w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/7a391395b7569cf2e9b340fdb4dc7496/69538/tHKOZ-2345%3A37250.png 160w", "/static/7a391395b7569cf2e9b340fdb4dc7496/72799/tHKOZ-2345%3A37250.png 320w", "/static/7a391395b7569cf2e9b340fdb4dc7496/6af66/tHKOZ-2345%3A37250.png 640w", "/static/7a391395b7569cf2e9b340fdb4dc7496/d9199/tHKOZ-2345%3A37250.png 960w", "/static/7a391395b7569cf2e9b340fdb4dc7496/21b4d/tHKOZ-2345%3A37250.png 1280w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/7a391395b7569cf2e9b340fdb4dc7496/6af66/tHKOZ-2345%3A37250.png",
              "alt": "建物",
              "title": "建物",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "全体構成",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E5%85%A8%E4%BD%93%E6%A7%8B%E6%88%90",
        "aria-label": "全体構成 permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`全体構成`}</h2>
    <p>{`Ameba Illustration Systemはモジュールの組み合わせによってイラストを作成できる仕組みになっています。キャラ、オブジェクト、背景という3種のモジュールがあり、 それぞれ独自のルールが規定されています。ガイドラインに沿ってモジュールを組み合わせることで、統一感のある様々なシーンのイラストを作成できます。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/da2ef38ab27bba9fbecea108474aaffc/21b4d/tHKOZ-2345%3A38086.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "48.12500000000001%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAACQklEQVQoz22SS28SURiG+Uvu3Bo3Lkx04dKVMW5MdGHU6KJ2oXGpjZekXqLGXqR3Q1opaJUKFBmwLS2gwXLpUGxLmTLTGS5zOY9hKI0mvsmX9zvnJO85Od/jcRybjuTaOksbo0iFCeKFcdelwiTf8l6+F32oWh1d1zEMw3XTNAGBEL1y3BwPhyrv5EiXFsnKUbKbka67FSFbiqFqKnJZZmd3x3VlX+FI4tCEwFPTKu6iYbQwWwJhg7DAOSxsaBomONBQDYQpaOstrJZNo61y0FDcoD2t3H3hy/AFHGGhqRqqquI4Dm2riWmZ7s2NhoGh6ZSqMiORcaYkH29Cw0i5NFLRSyAzQLNt8Cp6Edu28Nx9f4JW2+DTaoTpyBjxzCxruShfVny8/jzE8BcvE+EZ8ttFUoV1cpUNsuUf7O0f4E8NMBy5QV2vcH/mFHprH09o1YtuaHjjt3keuMq7+QdMxvp4GrzGZHiawEKQeDJBaOUrCyshnKZNtbqLXC6TzkdJ5ReRfiYJSEM4wsQj2rD8a4HLb09y5cVxHg72c3P6GHdGL5GILZNcXySR9jEVn+XWWL/7Df7wFH0jp1HVOlq9SUiaR1G6Q/J0sGlbLRIba1Sq2xQ2C1TrNeaiH0hmEphWk5q2xa5SpVgusaVscfb8GR49u+cGfIzNcf3JOfxL4z1sxD/jT6STbju37OdxYPDoyGXwwOiQRzAeJJVJuwjE1oKU5BJV5Xc3sAem7TjuhIXdBXSvXmOzIrt9Z7/H2d/63/oPmb7NvKGrslMAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/da2ef38ab27bba9fbecea108474aaffc/2aaaf/tHKOZ-2345%3A38086.webp 160w", "/static/da2ef38ab27bba9fbecea108474aaffc/85e47/tHKOZ-2345%3A38086.webp 320w", "/static/da2ef38ab27bba9fbecea108474aaffc/75198/tHKOZ-2345%3A38086.webp 640w", "/static/da2ef38ab27bba9fbecea108474aaffc/691bc/tHKOZ-2345%3A38086.webp 960w", "/static/da2ef38ab27bba9fbecea108474aaffc/223e5/tHKOZ-2345%3A38086.webp 1280w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/da2ef38ab27bba9fbecea108474aaffc/69538/tHKOZ-2345%3A38086.png 160w", "/static/da2ef38ab27bba9fbecea108474aaffc/72799/tHKOZ-2345%3A38086.png 320w", "/static/da2ef38ab27bba9fbecea108474aaffc/6af66/tHKOZ-2345%3A38086.png 640w", "/static/da2ef38ab27bba9fbecea108474aaffc/d9199/tHKOZ-2345%3A38086.png 960w", "/static/da2ef38ab27bba9fbecea108474aaffc/21b4d/tHKOZ-2345%3A38086.png 1280w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/da2ef38ab27bba9fbecea108474aaffc/6af66/tHKOZ-2345%3A38086.png",
              "alt": "建物、車、木、人々",
              "title": "建物、車、木、人々",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "モジュール詳細-キャラ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E8%A9%B3%E7%B4%B0-%E3%82%AD%E3%83%A3%E3%83%A9",
        "aria-label": "モジュール詳細 キャラ permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`モジュール詳細 キャラ`}</h2>
    <p>{`キャラは頭部、上半身、下半身の3つのモジュールから構成されています。各パーツに何種類かのアセットが存在し、その組み合わせによって多彩な表現が可能となっています。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/eff463d0832a5bb48fc2eec23573dad7/21b4d/tHKOZ-2345%3A39875.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "54.37499999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/eff463d0832a5bb48fc2eec23573dad7/2aaaf/tHKOZ-2345%3A39875.webp 160w", "/static/eff463d0832a5bb48fc2eec23573dad7/85e47/tHKOZ-2345%3A39875.webp 320w", "/static/eff463d0832a5bb48fc2eec23573dad7/75198/tHKOZ-2345%3A39875.webp 640w", "/static/eff463d0832a5bb48fc2eec23573dad7/691bc/tHKOZ-2345%3A39875.webp 960w", "/static/eff463d0832a5bb48fc2eec23573dad7/223e5/tHKOZ-2345%3A39875.webp 1280w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/eff463d0832a5bb48fc2eec23573dad7/69538/tHKOZ-2345%3A39875.png 160w", "/static/eff463d0832a5bb48fc2eec23573dad7/72799/tHKOZ-2345%3A39875.png 320w", "/static/eff463d0832a5bb48fc2eec23573dad7/6af66/tHKOZ-2345%3A39875.png 640w", "/static/eff463d0832a5bb48fc2eec23573dad7/d9199/tHKOZ-2345%3A39875.png 960w", "/static/eff463d0832a5bb48fc2eec23573dad7/21b4d/tHKOZ-2345%3A39875.png 1280w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/eff463d0832a5bb48fc2eec23573dad7/6af66/tHKOZ-2345%3A39875.png",
              "alt": "パーツを組み合わせて作られた4人のイラスト",
              "title": "パーツを組み合わせて作られた4人のイラスト",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "モジュール詳細-オブジェクト",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E8%A9%B3%E7%B4%B0-%E3%82%AA%E3%83%96%E3%82%B8%E3%82%A7%E3%82%AF%E3%83%88",
        "aria-label": "モジュール詳細 オブジェクト permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`モジュール詳細 オブジェクト`}</h2>
    <p>{`「オブジェクトモジュール」はそれぞれ単品でも使用でき、「キャラモジュール」と組み合わせることも可能です。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/1a44d643554bf5fa39c9ec12baa0d451/21b4d/tHKOZ-2345%3A40696.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "54.37499999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/1a44d643554bf5fa39c9ec12baa0d451/2aaaf/tHKOZ-2345%3A40696.webp 160w", "/static/1a44d643554bf5fa39c9ec12baa0d451/85e47/tHKOZ-2345%3A40696.webp 320w", "/static/1a44d643554bf5fa39c9ec12baa0d451/75198/tHKOZ-2345%3A40696.webp 640w", "/static/1a44d643554bf5fa39c9ec12baa0d451/691bc/tHKOZ-2345%3A40696.webp 960w", "/static/1a44d643554bf5fa39c9ec12baa0d451/223e5/tHKOZ-2345%3A40696.webp 1280w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/1a44d643554bf5fa39c9ec12baa0d451/69538/tHKOZ-2345%3A40696.png 160w", "/static/1a44d643554bf5fa39c9ec12baa0d451/72799/tHKOZ-2345%3A40696.png 320w", "/static/1a44d643554bf5fa39c9ec12baa0d451/6af66/tHKOZ-2345%3A40696.png 640w", "/static/1a44d643554bf5fa39c9ec12baa0d451/d9199/tHKOZ-2345%3A40696.png 960w", "/static/1a44d643554bf5fa39c9ec12baa0d451/21b4d/tHKOZ-2345%3A40696.png 1280w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/1a44d643554bf5fa39c9ec12baa0d451/6af66/tHKOZ-2345%3A40696.png",
              "alt": "モニター、ドライヤー、シューズ、パンツ、哺乳瓶、ノート、コメント、洋服",
              "title": "モニター、ドライヤー、シューズ、パンツ、哺乳瓶、ノート、コメント、洋服",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "モジュール詳細-背景",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E8%A9%B3%E7%B4%B0-%E8%83%8C%E6%99%AF",
        "aria-label": "モジュール詳細 背景 permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`モジュール詳細 背景`}</h2>
    <p>{`背景のモジュールは単品で用いることは基本的には無く、 キャラモジュール等と組み合わせて使用します。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/2908b0bce3aaf32134535d33eb99dd64/21b4d/tHKOZ-2346%3A40920.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "26.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAAAsTAAALEwEAmpwYAAABBUlEQVQY02WQ607CQBCFef/H8BH86x9FQCMCoWCEYul9Kb3RemOXdPczBQwmnuSbZOZMziTTAWj0gS9ZnalpdIPBoLWmaZojxpx6pRRSSpSSaN3wrWo+9zv26uO402kDq/eMVTRkvZmwip7ZJhvSJKXaVSh5QO4lWZZRliV1XVNVFXlaUuQllt1j7vSw/fElMMtzHM8jjAWu5zNdWtyO73mxZ3jhCsdbEoQ+RVEcEUnMzfCKvnWNGwSI7ZZIiEugSAV3oy69SZ/+ZIAbecSJYOHMGM27WItH1u4bgR/gtYejEC+y8WOHp/mQwfSBV3dxCmzLPxmD0QZa62y3//tLOzf64v/qBzvneI6yJ+97AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/2908b0bce3aaf32134535d33eb99dd64/2aaaf/tHKOZ-2346%3A40920.webp 160w", "/static/2908b0bce3aaf32134535d33eb99dd64/85e47/tHKOZ-2346%3A40920.webp 320w", "/static/2908b0bce3aaf32134535d33eb99dd64/75198/tHKOZ-2346%3A40920.webp 640w", "/static/2908b0bce3aaf32134535d33eb99dd64/691bc/tHKOZ-2346%3A40920.webp 960w", "/static/2908b0bce3aaf32134535d33eb99dd64/223e5/tHKOZ-2346%3A40920.webp 1280w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/2908b0bce3aaf32134535d33eb99dd64/69538/tHKOZ-2346%3A40920.png 160w", "/static/2908b0bce3aaf32134535d33eb99dd64/72799/tHKOZ-2346%3A40920.png 320w", "/static/2908b0bce3aaf32134535d33eb99dd64/6af66/tHKOZ-2346%3A40920.png 640w", "/static/2908b0bce3aaf32134535d33eb99dd64/d9199/tHKOZ-2346%3A40920.png 960w", "/static/2908b0bce3aaf32134535d33eb99dd64/21b4d/tHKOZ-2346%3A40920.png 1280w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/2908b0bce3aaf32134535d33eb99dd64/6af66/tHKOZ-2346%3A40920.png",
              "alt": "横に並んだ複数の建物",
              "title": "横に並んだ複数の建物",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      